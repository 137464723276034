export var LocalizationRequestKeysType;
(function (LocalizationRequestKeysType) {
    LocalizationRequestKeysType["KeysWithoutTranslation"] = "KeysWithoutTranslation";
    LocalizationRequestKeysType["AllKeys"] = "AllKeys";
    LocalizationRequestKeysType["AllKeysWithoutEmpty"] = "AllKeysWithoutEmpty";
})(LocalizationRequestKeysType || (LocalizationRequestKeysType = {}));
export var LocalizationAnswerType;
(function (LocalizationAnswerType) {
    LocalizationAnswerType["File"] = "File";
    LocalizationAnswerType["Message"] = "Message";
})(LocalizationAnswerType || (LocalizationAnswerType = {}));
export var LocalizationType;
(function (LocalizationType) {
    LocalizationType["Frontend"] = "Frontend";
    LocalizationType["Backend"] = "Backend";
})(LocalizationType || (LocalizationType = {}));
export var DestinationType;
(function (DestinationType) {
    DestinationType["Activities"] = "Activities";
    DestinationType["Orders"] = "Orders";
    DestinationType["Thanks"] = "Thanks";
    DestinationType["TealRequests"] = "TealRequests";
    DestinationType["Surveys"] = "Surveys";
    DestinationType["SupportRequests"] = "SupportRequests";
    DestinationType["Posts"] = "Posts";
})(DestinationType || (DestinationType = {}));
